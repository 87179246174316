import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import H1Category from "../../../components/h1Category"
import ContentPage from "../../../layouts/contentPage"
import ProductBox from "../../../components/productBox"
import ProductBoxCta from "../../../components/productBox/productBoxCta"
import ProducstList from "../../../components/productsList"
import Seo from "../../../components/seo"

import ArrowRight from "../../../images/arrow-right.svg"
import ProductBanner from "../../../components/productBanner"




const Category = () => (
    <Layout lang={"EN"}>
      <Seo title="Oxygenetic Acid Peel" lang={"EN"} />
      <ContentPage>
            <H1Category color="blue">Acid Peel</H1Category>
            <ProducstList>

                <ProductBox data-color="blue">
                    <Link to="/en/products/anti-aging-peeling">
                        <StaticImage
                        src="../../images/media/products/acid/category/przeciwstarzeniowy-peeling.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Przeciwstarzeniowy peeling kwasowy"
                        />
                        <ProductBoxCta className="cta" data-color="blue">
                            <div>
                                <h3>Anti-aging
                                <br />acid peeling</h3>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="blue">
                    <Link to="/en/products/renewing-peeling">
                        <StaticImage
                        src="../../images/media/products/acid/category/odnawiajacy-peeling.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Odnawiający peeling kwasowy"
                        />
                        <ProductBoxCta className="cta" data-color="blue">
                            <div>
                            <h3>Renewing
                            <br />acid peeling</h3>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>
                <ProductBanner productLine="acid"/>


          </ProducstList>
      </ContentPage>
    </Layout>
)

export default Category
